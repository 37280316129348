/*------------------BODY-------------------*/
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,900;1,800&display=swap');
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0d0909;
  overflow-x: hidden;
}

hr{
  width: 35%;
  margin-bottom: 1rem;
}

/* a{
  text-decoration: none;
  color: white;
} */

/* a:hover{
  color: black
} */

.span-wrap{
  text-align: center;
  line-height: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.about-gallery{
  background-color: #B99E67;
}
/* .img{
  filter: grayscale(100%);
}

.img:hover {
  filter: grayscale(0%);
} */

/*---------------------LOGO IMG---------------------*/

.footer-img {
  width: 140px;
  height: 155px;
}

/* .logo-img{
  width: 165px;
  height: 175px;
} */

/*--------------------------------------------------*/

/*--------------------MENU SLIDER-------------------*/

.slider{
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid white;
  border-bottom: 1px solid white
}

.menu{
  width: 1024px;
  height: 610px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-qr{

  /* width: clamp(125px, 30vw, 225px); */
  width: clamp(150px, 35vw, 410px);

}

.right-arrow{
  position: absolute;
  top: 50%;
  right: 4%;
  font-size: 4rem;
  color: white;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow{
  position: absolute;
  top: 50%;
  left: 4%;
  font-size: 4rem;
  color: white;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide{
  transition-duration: 1s ease ;
} 

.slide.active {
  transition-duration: 1s ;
  transform: scale(1.10);
} 


@media screen and (max-width: 280px){
  .slider{
    height: 60vh;
  }
  .left-arrow{
    top: 88%;
    left:5%;
    font-size: 1.75rem;
  }
  .right-arrow{
    top: 88%;
    right:5%;
    font-size: 1.75rem;
  }
  .menu {
    width: 245px;
    height: 230px;
  }
}

@media screen and (min-width: 320px){
  .slider{
    height: 65vh;
  }
  .left-arrow{
    top: 88%;
    left: 10%;
    font-size: 1.75rem;
  }
  .right-arrow{
    top: 88%;
    right: 10%;
    font-size: 1.75rem;
  }
  .menu {
    width: 280px;
    height: 235px;
  }
}

@media screen and (min-width: 360px){
  .slider{
    height: 65vh;
  }
  .left-arrow{
    top: 88%;
    left: 10%;
    font-size: 1.75rem;
  }
  .right-arrow{
    top: 88%;
    right: 10%;
    font-size: 1.75rem;
  }
  .menu {
    width: 315px;
    height: 265px;
  }
}

@media screen and (min-width: 375px){
  .slider{
    height: 65vh;
  }
  .left-arrow{
    top: 88%;
    left: 10%;
    font-size: 1.5rem;
  }
  .right-arrow{
    top: 88%;
    right: 10%;
    font-size: 1.5rem;
  }
  .menu {
    width: 325px;
    height: 285px;
  }
}

@media screen and (min-width: 411px){
  .slider{
    height: 65vh;
  }
  .left-arrow{
    top: 88%;
    left: 10%;
    font-size: 1.75rem;
  }
  .right-arrow{
    top: 88%;
    right: 10%;
    font-size: 1.75rem;
  }
  .menu {
    width: 360px;
    height: 300px;
  }
}

@media screen and (min-width: 540px){
  .slider{
    height: 80vh;
  }
  .left-arrow{
    top: 88%;
    left: 15%;
    font-size: 2rem;
  }
  .right-arrow{
    top: 88%;
    right: 15%;
    font-size: 2rem;
  }
  .menu {
    width: 450px;
    height: 350px;
  }
}

@media screen and (min-width: 768px){
  .slider{
    height: 85vh;
  }
  .left-arrow{
    top: 88%;
    left: 10%;
    font-size: 3rem;
  }
  .right-arrow{
    top: 88%;
    right: 10%;
    font-size: 3rem;
  }
  .menu {
    width: 650px;
    height: 525px;
  }
}

@media screen and (min-width: 1024px){
  .slider{
    height: 85vh;
  }
  .left-arrow{
    top: 88%;
    left: 10%;
    font-size: 4rem;
  }
  .right-arrow{
    top: 88%;
    right: 10%;
    font-size: 4rem;
  }
  .menu {
    width: 850px;
    height: 725px;
  }
}

@media only screen and (min-width: 568px) and (orientation: landscape) {
  .slider{
    height: 150vh;
  }
  .left-arrow {
    top: 88%;
    left: 15%;
    font-size: 2rem;
  }
  .right-arrow {
    top: 88%;
    right: 15%;
    font-size: 2rem;
  }
  .menu{
    width: 425px;
    height: 300px;
  }
}

@media only screen and (min-width: 640px) and (orientation: landscape) {
  .slider{
    height: 165vh;
  }
  .left-arrow {
    top: 88%;
    left: 15%;
    font-size: 2rem;
  }
  .right-arrow {
    top: 88%;
    right: 15%;
    font-size: 2rem;
  }
  .menu{
    width: 525px;
    height: 375px;
  }
}

@media only screen and (min-width: 653px) and (orientation: landscape) {
  .slider{
    height: 165vh;
  }
  .left-arrow {
    top: 50%;
    left: 3%;
    font-size: 2rem;
  }
  .right-arrow {
    top: 50%;
    right: 3%;
    font-size: 2rem;
  }
  .menu{
    width: 475px;
    height: 280px;
  }
}

@media only screen and (min-width: 720px) and (orientation: landscape) {
  .slider{
    height: 100vh;
  }
  .left-arrow {
    top: 50%;
    left: 3%;
    font-size: 2rem;
  }
  .right-arrow {
    top: 50%;
    right: 3%;
    font-size: 2rem;
  }
  .menu{
    width: 525px;
    height: 375px;
  }
}

@media only screen and (min-width: 731px) and (orientation: landscape) {
  .slider{
    height: 165vh;
  }
  .left-arrow {
    top: 89%;
    left: 15%;
    font-size: 2rem;
  }
  .right-arrow {
    top: 89%;
    right: 15%;
    font-size: 2rem;
  }
  .menu{
    width: 600px;
    height: 425px;
  }
}

@media only screen and (min-width: 812px) and (orientation: landscape) {
  .slider{
    height: 165vh;
  }
  .left-arrow {
    top: 89%;
    left: 15%;
    font-size: 2rem;
  }
  .right-arrow {
    top: 89%;
    right: 15%;
    font-size: 2rem;
  }
  .menu{
    width: 600px;
    height: 400px;
  }
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
  .slider{
    height: 100vh;
  }
  .left-arrow {
    top: 50%;
    left: 4%;
    font-size: 3rem;
  }
  .right-arrow {
    top: 50%;
    right: 4%;
    font-size: 3rem;
  }
  .menu{
    width: 715px;
    height: 525px;
  }
}

@media only screen and (min-width: 1366px) and (orientation: landscape) {
  .slider{
    height: 100vh;
  }
  .left-arrow {
    top: 50%;
    left: 4%;
    font-size: 4rem;
  }
  .right-arrow {
    top: 50%;
    right: 4%;
    font-size: 4rem;
  }
  .menu{
    width: 955px;
    height: 750px;
  }
}


/* ------------------------------------------------- */
/* **********************Collage SLider***************/

.collage-image{
  border: 3px solid white;
  width: 665px;
  height: 510px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-a{
  position: absolute;
  top: 50%;
  right: 10%;
  font-size: 4rem;
  color: white;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-a{
  position: absolute;
  top: 50%;
  left: 10%;
  font-size: 4rem;
  color: white;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide-go{
  transition-duration: 1s ease ;

} 

.slide-start {
  transition-duration: 1s ;
  transform: scale(1.10);
} 


/* ****************** PLAYER WRAPPER *************** */


.player-wrapper{
  position: relative;
  padding-top: 56.25%;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;

}

.react-player{
  position: absolute;
  top: 0;
  left: 0;
}


/* ****************** ABOUT HOME *************** */

.about-pic-wrapper{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.about-img{
  /* border: 1px solid white; */
  border-radius: 25px;
  width: 625px;
  height: 435px;
}

@media screen and (max-width: 280px) {
  .about-pic-wrapper{
    height: inherit;
  }
  .about-img {
    width: 260px;
    height: 200px;
  }
}
@media screen and (min-width: 320px) {
  .about-pic-wrapper{
    height: inherit;
  }
  .about-img {
    width: 290px;
    height: 215px;
  }
}

@media screen and (min-width: 375px) {
  .about-pic-wrapper{
    height: inherit;
  }
  .about-img {
    width: 325px;
    height: 240px;;

  }
}

@media screen and (min-width: 411px) {
  .about-pic-wrapper{
    height: inherit;
  }
  .about-img {
    width: 385px;
    height: 290px;

  }
}


@media screen and (min-width: 720px) {

  .about-img {
    width: 450px;
    height: 320px;
  }
}
@media screen and (min-width: 768px) {
  
  .about-img {
    width: 500px;
    height: 325px;
  }
}

@media screen and (min-width: 1024px) {
  .about-pic-wrapper{
    height: inherit;
    padding-bottom: 4rem;
  }

  .about-img {
    width: 650px;
    height: 475px;
  }
}

@media only screen and (min-width: 1366px) and (orientation: landscape){
  .about-pic-wrapper{
    height: inherit;
  }
}


/* ************************************************* */


/* ****************** LOCATION *************** */
.map{
  width: 650px;
  height: 525px;
}

@media screen and (max-width: 280px) {
  .map{
    height: 265px;
    width: 255px;
  }
}

@media screen and (min-width: 320px) {
  .map{
    height: 265px;
    width: 255px;
  }
}

@media screen and (min-width: 375px){
  .map{
    height: 325px;
    width: 335px;
  }
}

@media screen and (min-width: 411px){
  .map{
    height: 300px;
    width: 325px;
  }
}

@media screen and (min-width: 768px){
  .map{
      height: 450px;
      width: 600px;
  }
}

@media screen and (min-width: 1024px){
  .map{
      height: 600px;
      width: 750px;
  }
}

@media only screen and (min-width: 720px) and (orientation: landscape) {
  .map {
    height: 450px;
    width: 500px;
  }
}

@media only screen and (min-width: 731px) and (orientation: landscape) {
  .map {
    height: 450px;
    width: 550px;
  }
}

@media only screen and (min-width: 812px) and (orientation: landscape) {
  .map {
    height: 375px;
    width: 500px;
  }
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
  .map {
    height: 550px;
    width: 650px;
  }
}

@media only screen and (min-width: 1366px) and (orientation: landscape) {
  .map {
    height: 750px;
    width: 850px;
  }
}

/* --------------------------------------------- */

/* ****************** Info *************** */

.info-wrap{
  width: 100%;
  height: 100%;
  padding: 0.5rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
}

.info-wrap h1{
  font-size: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;

}
.info-wrap p{
  font-size: 18px;
  letter-spacing: 1px;
}

/* ---------------------------------------------------------------------RESPONSIVE CSS *************** */

@font-face {
  font-family: 'Mason-Serif';
  src: url(../../font/MasonSerif.ttf);
  font-style: normal;
  font-weight: 600;
}

/* -------Menu Slideshow-------- */


@media screen and (max-width: 280px){
  
  .left-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .right-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .collage-image {
    width: 240px;
    height: 200px;
  }
}


@media screen and (min-width: 320px){
  .left-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .right-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .collage-image {
    width: 260px;
    height: 220px;
  }
}

@media screen and (min-width: 360px){
  .left-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .right-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .collage-image {
    width: 300px;
    height: 235px;
  }
}
@media screen and (min-width: 411px){
  .left-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .right-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .collage-image {
    width: 325px;
    height: 275px;
  }
}

@media screen and (min-width: 768px){
  .left-a{
    top: 87%;
    font-size: 4rem;
  }
  .right-a{
    top: 87%;
    font-size: 4rem;
  }
  .collage-image {
    width: 600px;
    height: 435px;
  }
}

@media screen and (min-width: 812px){
  
  .left-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .right-a{
    top: 85%;
    font-size: 2.5rem;
  }
  .collage-image {
    width: 285px;
    height: 245px;
  }
}

@media screen and (min-width: 1024px){
  .left-a{
    top: 50%;
    left: 3%;
    font-size: 5rem;
  }
  .right-a{
    top: 50%;
    right: 3%;
    font-size: 5rem;
  }
  .collage-image {
    width: 690px;
    height: 500px;
  }
}

@media only screen and (min-width: 568px) and (orientation: landscape) {
  .left-a{
    top: 50%;
    left: 5%;
    font-size: 3rem;
  }
  .right-a{
    top: 50%;
    right: 5%;
    font-size: 3rem;
  }
  .collage-image {
    width: 325px;
    height: 255px;
  }

}

@media only screen and (min-width: 731px) and (orientation: landscape) {
  .collage-image {
    width: 370px;
    height: 290px;
  }
}

@media only screen and (min-width: 823px) and (orientation: landscape) {
  .collage-image {
    width: 375px;
    height: 285px;
  }
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
  .collage-image {
    width: 580px;
    height: 400px;
  }
}
